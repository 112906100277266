// src/components/CookieConsentComponent.js
import React, { useEffect, useState } from 'react';
import CookieConsent, { Cookies } from 'react-cookie-consent';
import ReactGA from 'react-ga';
import './CookieConsentComponent.css';

const trackingId = process.env.REACT_APP_GA_TRACKING_ID; // Replace with your Google Analytics tracking ID

const initializeGoogleAnalytics = () => {
  console.log('Initializing Google Analytics', trackingId);
  ReactGA.initialize(trackingId);
  ReactGA.pageview(window.location.pathname + window.location.search);
};

const CookieConsentComponent = () => {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };

    window.addEventListener('resize', handleResize);
    
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(() => {
    if (Cookies.get('cookieConsent') === 'accepted') {
      initializeGoogleAnalytics();
    }
  }, []);

  const handleAccept = () => {
    Cookies.set('cookieConsent', 'accepted', { expires: 180 });
    initializeGoogleAnalytics();
  };

  const handleDecline = () => {
    Cookies.set('cookieConsent', 'declined', { expires: 180 });
  };

  return (
    <CookieConsent
      location="bottom"
      buttonText="Accept"
      declineButtonText="Decline"
      enableDeclineButton
      onAccept={handleAccept}
      onDecline={handleDecline}
      cookieName="cookieConsent"
      style={
        isDesktop
          ? {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '60%',
              margin: '0 auto',
              marginLeft: '20%',
              opacity: '0.8',
              fontFamily: 'Lato',
              background: '#0A252B',
            }
          : {}
      }
      buttonStyle={{fontFamily:"Lato", color: "#4e503b", background: "#87D2DB", fontSize: "13px" }}
      declineButtonStyle={{ fontFamily:"Lato", color: "#fff", background: "#842252", fontSize: "13px" }}
      expires={180}
    >
      This website has optional cookies that you may decline. Learn more in the <a style={{ color: "#87D2DB" }} href="/?privacy=true">
    privacy policy
  </a>.
   
    </CookieConsent>
  );
};

export default CookieConsentComponent;