import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import whiteLogo from '../../web2_images/yd_logo-small-tagline-light.png';
import TypeformEmbed from '../TypeformEmbed'; // Import your Typeform component

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%; // Ensure the overlay spans the full width
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050; // Ensure the z-index is high enough
`;

const ModalContent = styled.div`
  background: #08424C;
  padding: 60px;
  border-radius: 10px;
  box-shadow: 2px 2px 20px rgba(0,0,0,0.3);
  position: relative;
  width: 90%;
  max-width: 700px;

  @media (max-width: 768px) {
    width: 100%;
    height: 100%; // Ensure it covers full height on mobile
    border-radius: 0px;
    padding: 20px;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 17px;
  border: none;
  background: none;
  font-size: 2.5rem;
  color: #C7F5F9;
  cursor: pointer;
  @media (max-width: 768px) {
    top: 20px;
    right: 5px;
  }
}
`;

const Headline = styled.div`
  color: #C7F5F9;
  font-size: 2rem;
  margin-bottom: 10px;
  text-align: center;
  padding: 10px;
  margin-top: 30px;
`;

const Headline2 = styled.div`
  color: #C7F5F9;
  font-size: 1.2rem;
  margin-bottom: 10px;
  text-align: center;
  padding: 10px;
`;

const Subtext = styled.div`
  color: #C7F5F9;
  font-size: 1rem;
  margin-bottom: 20px;
  text-align: center;
  padding: 10px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center; // Center the form elements
`;

const FormGroup = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: 100%; // Ensure the group spans full width
  max-width: 500px; // Optional: limit the max width
`;

const Label = styled.label`
  width: 150px;
  color: #C7F5F9;
  font-size: 1rem;
`;

const Input = styled.input`
  flex: 1;
  padding: 10px;
  background-color: #285C70;
  border: 1px solid #07384E;
  border-radius: 5px;
  color: #C7F5F9;
`;

const Button = styled.button`
  width: 150px;
  padding: 10px;
  background-color: #C7F5F9;
  border: none;
  border-radius: 5px;
  color: #07384E;
  font-weight: bold;
  cursor: pointer;
  text-transform: uppercase;
  margin-top: 20px;
`;

const Logo = styled.img`
  margin-top: 40px;
  margin-bottom: -20px;
  width: 180px;
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const SignupModalDBCall = ({ isOpen, onClose }) => {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [linkedin, setLinkedin] = useState('');

  const [isSubscribed, setIsSubscribed] = useState(false);
  const modalRef = useRef(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log('Form submitted:', { firstName, lastName, email, city, country, linkedin });
    const url = 'subscribe';
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ firstName, lastName, email, city, country, linkedin }),
      });

      const data = await response.json();
      setIsSubscribed(true);
    } catch (error) {
      console.error('Signup failed:', error);
    }
  };

  const handleClickOutside = (event) => {
    if (isSubscribed) return;
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, isSubscribed]);

  if (!isOpen) return null;

  return (
    <ModalOverlay>
      <ModalContent ref={modalRef}>
        <CloseButton onClick={onClose}>×</CloseButton>
        {isSubscribed ? (
          <>
        <Headline>A warm Welcome to our Ocean Community 🐋</Headline>
        <Headline2> We hope this is the start of something beautiful. As a first action, we invite you to complete our user survey.</Headline2>

            <TypeformEmbed
              url="https://p19teneskuk.typeform.com/to/KazlVkvO#user_id=xxxxx"
              style={{ width: '100%', height: 'auto', minHeight: '500px', marginBottom: '-20px' }}
            />
          </>
        ) : (
          <>
            <Headline>Welcome OceanKeepers</Headline>
            <Subtext>
            You are needed and Now is your time. We’re inviting you to a community that is about making a change in the way we live. It’s about reconnecting with Nature and through that with ourselves. It’s above all, about saving and caretaking our Ocean. We can do together, but we can only do it Together.
            </Subtext>
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label htmlFor="firstName">First Name*</Label>
                <Input
                  id="firstName"
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  placeholder="Adriana"
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="lastName">Last Name*</Label>
                <Input
                  id="lastName"
                  type="text"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  placeholder="Seasteward"
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="email">Email*</Label>
                <Input
                  id="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="adriana@ocean.com"
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="city">City</Label>
                <Input
                  id="city"
                  type="text"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  placeholder="Stockholm"
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="country">Country</Label>
                <Input
                  id="country"
                  type="text"
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                  placeholder="Sweden"
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="linkedin">LinkedIn Url</Label>
                <Input
                  id="linkedin"
                  type="url"
                  value={linkedin}
                  onChange={(e) => setLinkedin(e.target.value)}
                  placeholder="www.linkedin.com/in/adriana-seasteward"
                />
              </FormGroup>
              <Button type="submit">Submit</Button>
            </Form>
            <Logo src={whiteLogo} alt="Logo" />
          </>
        )}
      </ModalContent>
    </ModalOverlay>
  );
};

export default SignupModalDBCall;
